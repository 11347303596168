import { store } from "../store/store.js";

// PUBLIC - AUTHENTICATION
const Authentication = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/authentication/Index.vue"
  );
const Login = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/authentication/Login.vue"
  );
const SubmitTotp = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/authentication/SubmitTotp.vue"
  );
const RequestPwd = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/authentication/RequestPwd.vue"
  );
const UpdatePwd = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/authentication/UpdatePwd.vue"
  );
const Unlock = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/authentication/Unlock.vue"
  );
const PageNotFound = () =>
  import(
    /* webpackChunkName: "group-authentication" */ "../components/public/PageNotFound.vue"
  );

// PRIVATE - EMPLOYEES ----------------------------------------------------------------------------------------------------------------------------------------
const AdminLayout = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/Layout.vue"
  );
/* children */

const CrmLayout = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/CrmLayout.vue"
  );
/* CRM children */
const People = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/people/Index.vue"
  );
const Clients = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/clients/Index.vue"
  );
const Administrators = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/administrators/Index.vue"
  );
const BankContacts = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/bank_contacts/Index.vue"
  );
const Employees = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/employees/Index.vue"
  );
const Entities = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entities/Index.vue"
  );
const EntityRelations = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_relations/Index.vue"
  );
const Accounts = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/accounts/Index.vue"
  );
const AccountRates = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/account_rates/Index.vue"
  );
const FeeRates = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/fee_rates/Index.vue"
  );
const PersonConsultants = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/person_consultants/Index.vue"
  );
const ContactInformations = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/contact_informations/Index.vue"
  );
const Deposits = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/deposits/Index.vue"
  );
const MeetingSummaries = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/meeting_summaries/Index.vue"
  );
const Ownerships = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/ownerships/Index.vue"
  );
const Users = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/users/Index.vue"
  );
const Onboarding = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/onboarding/Index.vue"
  );
const OnboardingSetup = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/onboarding_setup/Index.vue"
  );
const AllocationLimits = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/allocation_limit/Index.vue"
  );
const RiskAssessments = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/risk_assessments/Index.vue"
  );
const BenchmarkSetups = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_benchmarks/EntityBenchmarkIndex.vue"
  );
const BenchmarkWeights = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_benchmarks/EntityBenchmarkIndex.vue"
  );
const InvoiceTypes = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/invoice_types/Index.vue"
  );
const PersonFamilies = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/person_families/Index.vue"
  );
const Reports = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/reports/Index.vue"
  );
const EntityInvoiceTypes = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_invoice_types/Index.vue"
  );
const EntityCommitments = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_commitments/Index.vue"
  );
const EntityBenchmarks = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_benchmarks/EntityBenchmarkIndex.vue"
  );

// MARKET LAYOUT ----------------------------------------------------------------------------------------------------------------------------------------
const MarketLayout = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/MarketLayout.vue"
  );
/* Markets children */
const Issues = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/issues/Index.vue"
  );
const Coracts = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/coracts/Index.vue"
  );
const Currencies = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/currencies/Index.vue"
  );
const CurrencyRates = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/currency_rates/Index.vue"
  );
const Covariances = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/covariances/Index.vue"
  );
const VehicleCostEstimates = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/vehicle_cost_estimates/Index.vue"
  );
const EntityVehicleCostEstimates = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/entity_vehicle_cost_estimates/Index.vue"
  );
const AssetClassGroups = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/asset_class_groups/Index.vue"
  );
const IssueTaxStructures = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/issue_tax_structures/Index.vue"
  );

  const IssueFundGroups = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/markets/issue_fund_groups/IssueFundGroupIndex.vue"
  );

// INVESTMENT LAYOUT ----------------------------------------------------------------------------------------------------------------------------------------
const InvestmentLayout = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/InvestmentLayout.vue"
  );
/* Investments children (quasi) */
const InvestmentCosts = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/investment_costs/Index.vue"
  );
const Fees = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/fees/Index.vue"
  );
const EntityInvoiceFees = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/crm/entity_invoice_fees/Index.vue"
  );
const ManualFees = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/manual_fees/Index.vue"
  );
const LossWarningLosses = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/loss_warnings/losses/Index.vue"
  );
const Investments = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/investments/InvestmentLayout.vue"
  );
const InvestmentReports = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/investment_reports/Index.vue"
  );
const Report = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/report/Report.vue"
  );
const Performance = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/investments/performance/Index.vue"
  );

// MISC ---------------------------------------------------------------------------------------------------------------------------------------------------------
const MiscLayout = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/MiscLayout.vue"
  );
/* MISC children */
const Documents = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/documents/Index.vue"
  );
const DocumentPrivileges = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/document_privileges/Index.vue"
  );
const LaunderingEvents = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/laundering_events/Index.vue"
  );
const Events = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/events/Index.vue"
  );
const EventLoads = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/event_loads/Index.vue"
  );
const EventLoadLogs = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/event_load_logs/Index.vue"
  );
const HealthChecks = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/health_checks/Index.vue"
  );
const Routes = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/routes/Index.vue"
  );
const ApplicationRecordLogs = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/application_record_logs/Index.vue"
  );
const SailsLogs = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/sails_logs/Index.vue"
  );
const SailsLogChecks = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/sails_log_checks/Index.vue"
  );
const SailsLogSubscriptions = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/sails_log_subscriptions/Index.vue"
  );
const TradeSuggestions = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/trade_suggestions/Index.vue"
  );
const DataSelectors = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/data_selectors/Index.vue"
  );
const MailGroups = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/mail_groups/Index.vue"
  );
const MailGroupUsers = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/mail_groups/Index.vue"
  );
const MailGroupActions = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/mail_groups/ActionIndex.vue"
  );
const EventRefundSplits = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/event_refund_splits/EventRefundSplitIndex.vue"
  );
const Banks = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/banks/BankIndex.vue"
  );
const SailsHelpTexts = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/sails_help_texts/SailsHelpTextIndex.vue"
  );
const TransactionRules = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/transaction_rules/TransactionRuleIndex.vue"
  );
const AccountBalances = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/account_balances/AccountBalanceIndex.vue"
  );
const DepositBalances = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/deposit_balances/DepositBalanceIndex.vue"
  );
const JyskeBankIsinMappings = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/admin/misc/jyske_bank_isin_mappings/JyskeBankIsinMappingIndex.vue"
  );


// PRIVATE - INVESTOR ----------------------------------------------------------------------------------------------------------------------------------------
const InvestorLayout = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/Layout.vue"
  );
const InvestorHome = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/home/Index.vue"
  );
const InvestorAccounts = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/accounts/Index.vue"
  );
const InvestorDeposits = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/deposits/Index.vue"
  );
const InvestorIssues = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/issues/Index.vue"
  );
const InvestorInvestments = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/report/Index.vue"
  );
const InvestorReport = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/report/Report.vue"
  );
const InvestorDocuments = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/documents/Index.vue"
  );
const DocumentDownload = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/investor/documents/Download.vue"
  );
const Reportings = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/admin/misc/reportings/ReportingIndex.vue"
  );
const ReportingTypes = () =>
  import(
    /* webpackChunkName: "group-investor" */ "../components/admin/misc/reportings/ReportingTypeIndex.vue"
  );
// const InvestorRoutes = ()=> import(/* webpackChunkName: "group-investor" */ '../components/investor/routes/Index.vue')

const routes = [
  {
    path: "/",
    component: Authentication,
    children: [
      {
        path: "",
        component: Login,
        name: "login",
      },
      {
        path: "totp",
        name: "totp",
        component: SubmitTotp,
        props: (route) => ({ query: route.query.q }),
      },
      {
        path: "request_password",
        name: "request_password",
        component: RequestPwd,
      },
      {
        path: "update_password",
        name: "update_password",
        component: UpdatePwd,
        props: true,
      },
      {
        path: "unlock",
        name: "unlock",
        component: Unlock,
        props: true,
      },
    ],
  },
  {
    path: "/employee",
    name: "employee",
    component: AdminLayout,
    beforeEnter(to, from, next) {
      if (store.getters.current_user_is_employee) {
        import(
          /* webpackChunkName: "group-admin" */ "src/store/group-admin-store"
        );
        next();
      } else {
        store.commit("loginRedirectPath", to);
        next("/");
      }
    },
    children: [
      {
        component: CrmLayout,
        label: "CRM",
        name: "crm",
        path: "/employee/crm",
        children: [
          {
            component: Clients,
            label: "Kunder",
            name: "clients-default",
            path: "",
          },
          {
            component: People,
            label: "Personer",
            name: "people",
            path: "/employee/crm/people/:id?",
            props: true,
          },
          {
            component: Clients,
            label: "Kunder",
            name: "clients",
            path: "/employee/crm/clients/:id?",
            props: true,
          },
          {
            component: Administrators,
            label: "Administratorer",
            name: "administrators",
            path: "/employee/crm/administrators/:id?",
            props: true,
            index_component: false,
          },
          {
            component: Employees,
            label: "Kollegaer",
            name: "employees",
            path: "/employee/crm/employees/:id?",
            props: true,
            index_component: false,
          },
          {
            component: BankContacts,
            label: "Bank kontakter",
            name: "bank_contacts",
            path: "/employee/crm/bank_contacts/:id?",
            props: true,
            index_component: false,
          },
          {
            component: Users,
            label: "Brugere",
            name: "users",
            path: "/employee/crm/users/:id?",
          },
          {
            component: PersonConsultants,
            label: "Rådgiver kunde relationer",
            name: "person_consultants",
            path: "/employee/crm/person_consultants",
          },
          {
            component: ContactInformations,
            label: "Kontaktinformationer",
            name: "contact_informations",
            path: "/employee/crm/contact_informations",
          },
          {
            component: Ownerships,
            label: "Ejerskaber",
            name: "ownerships",
            path: "/employee/crm/ownerships",
          },
          {
            component: Entities,
            label: "Mandater",
            name: "entities",
            path: "/employee/crm/entities/:id?",
            props: true,
          },
          {
            component: EntityRelations,
            label: "Mandatrealtioner",
            name: "entity_relations",
            path: "/employee/crm/entity_relations",
          },
          {
            component: FeeRates,
            label: "Honorarsatser",
            name: "fee_rates",
            path: "/employee/crm/fee_rates",
          },
          {
            component: Accounts,
            label: "Konti",
            name: "accounts",
            path: "/employee/crm/accounts/:id?",
            props: true,
          },
          {
            component: AccountRates,
            label: "Kontorenter",
            name: "account_rates",
            path: "/employee/crm/account_rates",
          },
          {
            component: Deposits,
            label: "Depoter",
            name: "deposits",
            path: "/employee/crm/deposits/:id?",
            props: true,
          },
          {
            component: MeetingSummaries,
            label: "Mødereferater",
            name: "meeting_summaries",
            path: "/employee/crm/meeting_summaries",
          },
          {
            component: Onboarding,
            label: "Onboarding",
            name: "onboarding",
            path: "/employee/crm/onboarding",
          },
          {
            component: OnboardingSetup,
            label: "Onboarding setup",
            name: "onboarding_setup",
            path: "/employee/crm/onboarding_setup",
          },
          {
            component: AllocationLimits,
            label: "Mandatgrænser",
            name: "allocation_limits",
            path: "/employee/crm/allocation_limits",
          },
          {
            component: RiskAssessments,
            label: "KYC risikovurderinger",
            name: "risk_assessments",
            path: "/employee/crm/risk_assessments/:id?",
          },
          {
            component: InvoiceTypes,
            label: "Fakturamodeller",
            name: "invoice_types",
            path: "/employee/crm/invoice_types/:id?",
          },
          {
            component: PersonFamilies,
            label: "Familier",
            name: "person_families",
            path: "/employee/crm/person_families/:id?",
          },
          {
            component: Reports,
            label: "Rapporter",
            name: "reports",
            path: "/employee/crm/reports/",
          },
          {
            component: DocumentDownload,
            label: "Donwload rapport",
            name: "download_document",
            path: "download_document/:id?",
            props: true,
          },
          {
            component: EntityInvoiceTypes,
            label: "Mandatfakturering",
            name: "entity_invoice_types",
            path: "/employee/crm/entity_invoice_types/:id?",
          },
          {
            component: EntityCommitments,
            label: "Tilsagn",
            name: "entity_commitments",
            path: "/employee/crm/entity_commitments/:id?",
          },
          {
            component: EntityBenchmarks,
            label: "Benchmark pr. mandat",
            name: "entity_benchmarks",
            path: "/employee/crm/entity_benchmarks/:id?",
          },
        ],
      },
      {
        component: InvestmentLayout,
        label: "Investeringer",
        name: "investment_layout",
        path: "/employee/investments",
        children: [
          {
            component: InvestmentCosts,
            label: "Indirekte Investeringsomkostninger",
            name: "investment_costs",
            path: "/employee/investments/investment_costs",
            props: true,
          },
          {
            component: Fees,
            label: "Honorarer",
            name: "fees",
            path: "/employee/investments/fees/:id?",
            props: true,
          },
          {
            component: EntityInvoiceFees,
            label: "Honorarer",
            name: "entity_invoice_fees",
            path: "/employee/crm/entity_invoice_fees/",
            props: true,
          },
          {
            component: ManualFees,
            label: "Manuelle honorarer",
            name: "manual_fees",
            path: "/employee/investments/manual_fees/:id?",
            props: true,
          },
          {
            component: LossWarningLosses,
            label: "Tabsadvarsler",
            name: "loss_warning_losses",
            path: "/employee/investments/loss_warnings/losses/:id?",
            props: true,
          },
          {
            component: InvestmentReports,
            label: "Gemte investerigsrapporter",
            name: "investment_reports",
            path: "/employee/investments/investment_reports",
          },
          {
            component: Investments,
            label: "Investeringer",
            name: "investments",
            path: "/employee/investments/investments",
          },
          {
            name: "performance",
            path: "performance",
            component: Performance,
          },
          {
            component: Report,
            label: "Rapport",
            name: "report",
            path: "/employee/investments/report/:id?",
            // alias:"/employee/investments/report/:id?",
            props: true,
          },
        ],
      },
      {
        component: MarketLayout,
        label: "Investeringer",
        name: "markets_layout",
        path: "/employee/markets",
        children: [
          {
            component: Issues,
            label: "Værdipapirer",
            name: "issues",
            path: "/employee/markets/issues/:id?",
            props: true,
          },
          {
            component: Coracts,
            label: "Corporate Actions",
            name: "coracts",
            path: "/employee/markets/coracts/:id?",
            props: true,
          },
          {
            component: Covariances,
            label: "Kovariation",
            name: "covariances",
            path: "/employee/markets/covariances",
          },
          {
            component: Currencies,
            label: "Valuta",
            name: "currencies",
            path: "/employee/markets/currencies/:id?",
            props: true,
          },
          {
            component: CurrencyRates,
            label: "Investeringer",
            name: "currency_rates",
            path: "/employee/markets/currency_rates",
          },
          {
            component: VehicleCostEstimates,
            label: "VP omkostningssatser",
            name: "vehicle_cost_estimates",
            path: "/employee/markets/vehicle_cost_estimates/:id?",
            props: true,
          },
          {
            component: EntityVehicleCostEstimates,
            label: "Specifikke refusionssatser",
            name: "entity_vehicle_cost_estimates",
            path: "/employee/markets/entity_vehicle_cost_estimates/:id?",
            props: true,
          },
          {
            component: AssetClassGroups,
            label: "Aktivklasse undergrupper",
            name: "asset_class_groups",
            path: "/employee/markets/asset_class_groups/:id?",
            props: true,
          },
          {
            component: IssueTaxStructures,
            label: "Skattekoder",
            name: "issue_tax_structures",
            path: "/employee/markets/issue_tax_structures/:id?",
            props: true,
          },
          {
            component: IssueFundGroups,
            label: "Fondsgrupper",
            name: "issue_fund_groups",
            path: "/employee/markets/issue_fund_groups/:id?",
            props: true,
          },
        ],
      },
      {
        component: MiscLayout,
        label: "Diverse",
        name: "misc_layout",
        path: "/employee/misc",
        children: [
          {
            component: Documents,
            label: "Dokumenter",
            name: "documents",
            path: "/employee/misc/documents/:id?",
            props: true,
          },
          {
            component: DocumentPrivileges,
            label: "Dokument privilegier",
            name: "document_privileges",
            path: "/employee/misc/document_privileges",
          },
          {
            component: LaunderingEvents,
            label: "Transaktionshvidvask",
            name: "laundering_events",
            path: "/employee/misc/laundering_events",
          },
          {
            component: Events,
            label: "Transaktioner",
            name: "events",
            path: "/employee/misc/events/:id?",
            props: true,
          },
          {
            component: EventLoads,
            label: "Transaktioner - import",
            name: "event_loads",
            path: "/employee/misc/event_loads/:id?",
            props: true,
          },
          {
            component: EventLoadLogs,
            label: "Transaktioner - import log",
            name: "event_load_logs",
            path: "/employee/misc/event_load_logs/:id?",
            props: true,
          },
          {
            component: ApplicationRecordLogs,
            label: "Logposter",
            name: "application_record_logs",
            path: "/employee/misc/application_record_logs",
          },
          {
            component: SailsLogs,
            label: "Systemlog",
            name: "sails_logs",
            path: "/employee/misc/sails_logs",
          },
          {
            component: SailsLogChecks,
            label: "Systemlog opsætning",
            name: "sails_log_checks",
            path: "/employee/misc/sails_log_checks",
          },
          {
            component: SailsLogSubscriptions,
            label: "Systemlog modtagere",
            name: "sails_log_subscriptions",
            path: "/employee/misc/sails_log_subscriptions",
          },
          {
            component: TradeSuggestions,
            label: "Handelsforslag",
            name: "trade_suggestions",
            path: "/employee/misc/trade_suggestions",
          },
          {
            component: HealthChecks,
            label: "Systemhelbed",
            name: "health_checks",
            path: "/employee/misc/health_checks",
          },
          {
            component: Routes,
            label: "Ruter",
            name: "routes",
            path: "/employee/misc/routes",
          },
          {
            component: Reportings,
            label: "Rapporteringer",
            name: "routes",
            path: "/employee/misc/reportings",
          },
          {
            component: ReportingTypes,
            label: "Rapporteringstyper",
            name: "routes",
            path: "/employee/misc/reporting_types",
          },
          {
            component: DataSelectors,
            label: "Dataudvalg",
            name: "data_selectorrs",
            path: "/employee/misc/data_selectors",
          },
          {
            component: MailGroups,
            label: "Mailgrupper",
            name: "mail_groups",
            path: "/employee/misc/mail_groups",
          },
          {
            component: MailGroupUsers,
            label: "Mailgruppebrugere",
            name: "mail_group_users",
            path: "/employee/misc/mail_group_users",
          },
          {
            component: MailGroupActions,
            label: "Mailgruppehandlinger",
            name: "mail_group_actions",
            path: "/employee/misc/mail_group_actions",
          },
          {
            component: EventRefundSplits,
            label: "Refusionssplit",
            name: "event_refund_splits",
            path: "/employee/misc/event_refund_splits",
          },
          {
            component: Banks,
            label: "Banker",
            name: "banks",
            path: "/employee/misc/banks"
          },
          {
            component: SailsHelpTexts,
            label: "Hjælpetekster",
            name: "sails_log_texts",
            path: "/employee/misc/sails_help_texts",
          },
          {
            component: TransactionRules,
            label: "Transaktionsregler",
            name: "transaction_rules",
            path: "/employee/misc/transaction_rules",
          },
          {
            component: AccountBalances,
            label: "Kontosaldi",
            name: "account_balances",
            path: "/employee/misc/account_balances",
          },
          {
            component: DepositBalances,
            label: "DepositSaldi",
            name: "deposit_balances",
            path: "/employee/misc/deposit_balances",
          },
          {
            component: JyskeBankIsinMappings,
            label: "JyskeBank isin",
            name: "jyske_bank_isin_mappings",
            path: "/employee/misc/jyske_bank_isin_mappings",
          },
        ],
      },
    ],
  },
  {
    path: "/investor",
    name: "investor",
    component: InvestorLayout,
    beforeEnter(to, from, next) {
      if (store.getters.current_user_is_investor) {
        import(
          /* webpackChunkName: "group-investor" */ "src/store/group-investor-store"
        );
        next();
      } else {
        store.commit("loginRedirectPath", to);
        next("/");
      }
    },
    children: [
      {
        component: InvestorReport,
        label: "Rapportering",
        //name: 'investor_report',
        path: "",
      },
      {
        component: InvestorHome,
        label: "Hjem",
        name: "investor_home",
        path: "/investor/home",
      },
      {
        component: InvestorAccounts,
        label: "Konto",
        name: "investor_accounts",
        path: "/investor/accounts/:id?",
        props: true,
      },
      {
        component: InvestorDeposits,
        label: "Depot",
        name: "investor_deposits",
        path: "/investor/deposits/:id?",
        props: true,
      },
      {
        component: InvestorIssues,
        label: "Værdipapirer",
        name: "investor_issues",
        path: "/investor/issues/:id?",
        props: true,
      },
      {
        component: InvestorInvestments,
        label: "Rapportering",
        name: "investor_investments",
        path: "/investor/investments",
        props: true,
      },
      {
        component: InvestorReport,
        label: "Rapportering",
        name: "investor_report",
        path: "/investor/report/:id?",
        props: true,
      },
      {
        component: InvestorDocuments,
        label: "Dokumenter",
        name: "investor_documents",
        path: "documents/:id?",
        props: true,
      },
      {
        component: DocumentDownload,
        label: "Donwload rapport",
        name: "investor_download_document",
        path: "/investor/download_document/:id?",
        props: true,
      },
      {
        component: DocumentDownload,
        label: "Donwload rapport",
        name: "investor_download_document",
        path: "/download_document/:id?",
        props: true,
      },
    ],
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

export default routes;
